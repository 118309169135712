<template>
  <div class="flex flex-col gap-4 p-4 box">
    <section class="flex justify-between items-center">
      <div class="flex gap-2">
        <font-awesome-icon icon="file-invoice" style="width: 25px; height: 25px;color: #1C3FAA"/>
        <span class="text-base font-bold" style="color: #1C3FAA">Informacion caja</span>
      </div>
      <Button icon="pi pi-arrow-left"
              class="p-badge-info"
              @click="$router.go(-1)"
      />
    </section>

    <section class="flex gap-4 items-center">
      <span class="text-base font-bold">Tipo</span>
      <Dropdown class="w-1/6" v-model="selectedType" :options="types" optionLabel="name" optionValue="value" placeholder="Seleccione tipo" />
    </section>

    <section class="flex">
      <div class="w-2/3 p-4">
        <table class="w-full tabla1">
          <tr>
            <th colspan="2" class="text-left azul">
              <font-awesome-icon icon="money-bill-alt" style="width: 20px; height: 20px;color: #1C3FAA" class="mr-2"/>
              Billetes
            </th>
          </tr>
          <tr>
            <td>
              <div class="flex gap-4 items-center">
                <span class="text-sm font-bold">$ 100.000</span>
                <div class="flex flex-col">
                  <span>Cantidad</span>
                  <InputNumber v-model="billetes['100000']" mode="decimal" :min="-1" tabindex="1" />
                </div>
                <span class="text-sm font-bold">Total {{ $h.formatCurrency(billetes['100000'] * 100000,2) }}</span>
              </div>
            </td>
            <td>
              <div class="flex gap-4 items-center">
                <span class="text-sm font-bold">$ 2.000</span>
                <div class="flex flex-col">
                  <span>Cantidad</span>
                  <InputNumber v-model="billetes['2000']" mode="decimal" :min="-1" tabindex="6" />
                </div>
                <span class="text-sm font-bold">Total {{ $h.formatCurrency(billetes['2000'] * 2000,2) }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="flex gap-4 items-center">
                <span class="text-sm font-bold">$ 50.000</span>
                <div class="flex flex-col">
                  <span>Cantidad</span>
                  <InputNumber v-model="billetes['50000']" mode="decimal" :min="-1" tabindex="2" />
                </div>
                <span class="text-sm font-bold">Total {{ $h.formatCurrency(billetes['50000'] * 50000,2) }}</span>
              </div>
            </td>
            <td>
              <div class="flex gap-4 items-center">
                <span class="text-sm font-bold">$ 1.000</span>
                <div class="flex flex-col">
                  <span>Cantidad</span>
                  <InputNumber v-model="billetes['1000']" mode="decimal" :min="-1" tabindex="7" />
                </div>
                <span class="text-sm font-bold">Total {{ $h.formatCurrency(billetes['1000'] * 1000,2) }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="flex gap-4 items-center">
                <span class="text-sm font-bold">$ 20.000</span>
                <div class="flex flex-col">
                  <span>Cantidad</span>
                  <InputNumber v-model="billetes['20000']" mode="decimal" :min="-1" tabindex="3" />
                </div>
                <span class="text-sm font-bold">Total {{ $h.formatCurrency(billetes['20000'] * 20000,2) }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="flex gap-4 items-center">
                <span class="text-sm font-bold">$ 10.000</span>
                <div class="flex flex-col">
                  <span>Cantidad</span>
                  <InputNumber v-model="billetes['10000']" mode="decimal" :min="-1" tabindex="4" />
                </div>
                <span class="text-sm font-bold">Total {{ $h.formatCurrency(billetes['10000'] * 10000,2) }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="flex gap-4 items-center">
                <span class="text-sm font-bold">$ 5.000</span>
                <div class="flex flex-col">
                  <span>Cantidad</span>
                  <InputNumber v-model="billetes['5000']" mode="decimal" :min="-1" tabindex="5" />
                </div>
                <span class="text-sm font-bold">Total {{ $h.formatCurrency(billetes['5000'] * 5000,2) }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <th colspan="2" class="text-left azul">
              <font-awesome-icon icon="money-bill-alt" style="width: 20px; height: 20px;color: #1C3FAA" class="mr-2"/>
              Monedas
            </th>
          </tr>
          <tr>
            <td>
              <div class="flex gap-4 items-center">
                <span class="text-sm font-bold">$ 1.000</span>
                <div class="flex flex-col">
                  <span>Cantidad</span>
                  <InputNumber v-model="monedas['1000']" mode="decimal" :min="-1" tabindex="8" />
                </div>
                <span class="text-sm font-bold">Total {{ $h.formatCurrency(monedas['1000'] * 1000,2) }}</span>
              </div>
            </td>
            <td>
              <div class="flex gap-4 items-center">
                <span class="text-sm font-bold">$ 100</span>
                <div class="flex flex-col">
                  <span>Cantidad</span>
                  <InputNumber v-model="monedas['100']" mode="decimal" :min="-1" tabindex="11" />
                </div>
                <span class="text-sm font-bold">Total {{ $h.formatCurrency(monedas['100'] * 100,2) }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="flex gap-4 items-center">
                <span class="text-sm font-bold">$ 500</span>
                <div class="flex flex-col">
                  <span>Cantidad</span>
                  <InputNumber v-model="monedas['500']" mode="decimal" :min="-1" tabindex="9" />
                </div>
                <span class="text-sm font-bold">Total {{ $h.formatCurrency(monedas['500'] * 500,2) }}</span>
              </div>
            </td>
            <td>
              <div class="flex gap-4 items-center">
                <span class="text-sm font-bold">$ 50</span>
                <div class="flex flex-col">
                  <span>Cantidad</span>
                  <InputNumber v-model="monedas['50']" mode="decimal" :min="-1" tabindex="12" />
                </div>
                <span class="text-sm font-bold">Total {{ $h.formatCurrency(monedas['50'] * 50,2) }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="flex gap-4 items-center">
                <span class="text-sm font-bold">$ 200</span>
                <div class="flex flex-col">
                  <span>Cantidad</span>
                  <InputNumber v-model="monedas['200']" mode="decimal" :min="-1" tabindex="10" />
                </div>
                <span class="text-sm font-bold">Total {{ $h.formatCurrency(monedas['200'] * 200,2) }}</span>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="w-1/3 p-4">
        <table class="w-full tabla2">
          <tr>
            <th colspan="2" class="text-left amarillo flex gap-4 justify-center items-center" style="height: 200px">
              <font-awesome-icon icon="toolbox" :style="{width: '80px', height: '80px', color: 'white' }" class="mr-2"/>
              <span class="text-4xl text-white">{{ selectedType === 1 ? 'Cierre de caja' : 'Arqueo' }}</span>
            </th>
          </tr>
          <tr>
            <td>
              <div class="flex gap-4 items-center justify-between">
                <span class="text-sm font-bold">Total Billetes</span>
                <span class="text-sm font-bold">{{ $h.formatCurrency(totalBilletes,2) }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="flex gap-4 items-center justify-between">
                <span class="text-sm font-bold">Total Monedas</span>
                <span class="text-sm font-bold">{{ $h.formatCurrency(totalMonedas,2) }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="flex gap-4 items-center justify-between">
                <span class="text-sm font-bold">Total Efectivo</span>
                <span class="text-sm font-bold">{{ $h.formatCurrency(totalBilletes + totalMonedas,2) }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="flex gap-4 items-center justify-between">
                <span class="text-sm font-bold">Total Pendientes</span>
                <span class="text-sm font-bold">{{ $h.formatCurrency(totalMonedas,2) }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="flex gap-4 items-center justify-between">
                <span class="text-sm font-bold">Efectivo + Pendientes + Préstamos</span>
                <span class="text-sm font-bold">{{ $h.formatCurrency(totalMonedas,2) }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="flex gap-4 items-center justify-between">
                <span class="text-sm font-bold">Saldo Caja</span>
                <span class="text-sm font-bold">{{ $h.formatCurrency(storeBalance, 2) }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="flex gap-4 items-center justify-between">
                <span class="text-sm font-bold">Diferencia</span>
                <span class="text-sm font-bold">{{ $h.formatCurrency(totalMonedas,2) }}</span>
              </div>
            </td>
          </tr>
        </table>
        <div class="flex justify-end mt-2 gap-4">
          <Button label="Cerrar Caja" @click="cerrarCaja" />
          <Button label="Cancelar" class="p-button-danger" @click="save" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import useIncome from '../../hooks/useIncome'
import useExpense from '../../hooks/useExpense'
import { useRoute } from 'vue-router'

export default {
  name: 'ArqueoCajaIndex',
  setup () {
    const store = useStore()
    const route = useRoute()
    const { getIncomes } = useIncome()
    const { getExpenses } = useExpense()
    const storeBalance = computed(() => store.getters['storePettyCash/getBalance'])
    const storeDate = computed(() => store.getters['storePettyCash/getStoreDate'])
    const date = ref('')
    const selectedType = ref(1)
    const billetes = ref({
      100000: 0,
      50000: 0,
      20000: 0,
      10000: 0,
      5000: 0,
      2000: 0,
      1000: 0
    })

    const monedas = ref({
      1000: 0,
      500: 0,
      200: 0,
      100: 0,
      50: 0
    })
    const totalBilletes = computed(() => {
      return billetes.value['100000'] * 100000 +
        billetes.value['50000'] * 50000 +
        billetes.value['20000'] * 20000 +
        billetes.value['10000'] * 10000 +
        billetes.value['5000'] * 5000 +
        billetes.value['2000'] * 2000 +
        billetes.value['1000'] * 1000
    })

    const cerrarCaja = () => {
      store.dispatch('storePettyCash/storeBalanceClose', {
        id: route.params.id
      })
    }

    onMounted(() => {
      getExpenses({ date: storeDate.value, query: { limit: 10, offset: 0 } })
      getIncomes({ date: storeDate.value, query: { limit: 10, offset: 0 } })
      store.dispatch('storePettyCash/gettingCashBalance', {
        date: storeDate.value
      })
    })

    return {
      date,
      selectedType,
      // types,
      totalBilletes,
      // totalMonedas,
      billetes,
      monedas,
      storeBalance,
      cerrarCaja
    }
  }
}
</script>

<style scoped>

table, th, td {
  border: 1px solid #E2E8F0;
  padding: 1rem;
}

.azul {
  background-color: #DEECFF;
}

.amarillo {
  background-color: #FECB47;
}

td {
  width: 50%;
}

</style>
